export default [
  {
    path: '/',
    name: 'landing',
    component: () => import('./views/landing')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('./views/utility/500')
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '/',
  }
]
